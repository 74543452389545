import {BulletPoint} from "../../components/utils";
import Typography from "@mui/material/Typography";
import "./Rates.css";

const Rates = () => {
    return <div className="Rates-container">
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                maxWidth: "1200px",
                margin: "auto",
                padding: "1em 0",
                backgroundColor: "#00000080",
                borderRadius: "0.25em"
            }}>
            <Typography variant="h3" style={{textAlign: "center"}}>Park Rates</Typography>
            <BulletPoint
                description="All reservations require a 2 night minimum during peak season (April 1st - Oct 1st)"/>
            <BulletPoint
                description="River sites (with electric/water/sewer) nightly $70.00 or $1200.00 per every 30 days"/>
            <BulletPoint
                description="Dry river campsites (no electric/water/sewer) $35.00 per night or $550.00 per every 30 days"/>
            <BulletPoint
                description="Overflow dry camping area near front of park $25.00 per night or $400.00 per every 30 days"/>
            <BulletPoint
                description="Full time residential rentals of our RVs, trailers or tiny homes - Rentals start at $1100.00 per month depending on location and size of rental. We DO NOT allow you to bring in your own RV, trailer, or tiny home for full-time rentals. (See Park Rules page for more info)"/>
        </div>
    </div>
}

export default Rates;
