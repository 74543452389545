import "./Home.css";

const Home = () => {
    return <div className="Home-container">
        <div className="Home-hero">
            <div className="Home-hero-text">
                <h1>Welcome to our peaceful, resort community.</h1>
                <h2 className="Home-hero-text-light">
                    Located on the Walker River, nestled between Hoye Canyon and the Toiyabe National Forest.
                </h2>
            </div>
        </div>
        <div className="Home-hero-sub-text">
            <h2>Welcome to Wellington Station Resort</h2>
            <div className="Home-about">
                Nestled on 30 scenic acres beside Hoye Canyon and the stunning Toiyabe National Forest, Wellington
                Station Resort offers a serene getaway like no other. The Walker River flows through the property
                year-round, providing a beautiful riverfront setting for most of our campsites.
            </div>
            {/*<h2>30 Acres in the Wilderness</h2>*/}
            {/*<div className="Home-about">*/}
            {/*    Wellington Station Resort is situated on 30 acres,*/}
            {/*    right next to Hoye Canyon and the Toiyabe National Forest.*/}
            {/*    The Walker River flows through the property year-round*/}
            {/*    and the majority of campsites sit along the riverfront.*/}
            {/*</div>*/}
            <div className="Home-flex-paragraph">
                <div>
                    <h2>Unplug and Recharge</h2>
                    <div className="Home-about">
                        Our resort is designed for those seeking peace, tranquility, and a place to recharge. When you
                        visit
                        Wellington Station, plan to completely unplug. Take in the natural beauty, relax, and enjoy the
                        unique
                        slice of paradise we call home. For your convenience, we offer clean and well-maintained
                        showers,
                        restrooms, and an on-site guest laundry for those who need it.
                    </div>
                </div>
                <div>
                    <h2>A Friendly Community and Local Activities</h2>
                    <div className="Home-about">
                        At Wellington Station, you’ll feel right at home with our friendly residents, some of the
                        kindest people
                        in Smith Valley. Local events such as car shows, bake sales, carnivals, and rummage sales take
                        place
                        regularly at the Community Hall, just a short walk—only 0.3 miles—from our park.
                    </div>
                </div>
            </div>
            <div className="Home-flex-paragraph">
                <div>
                    <h2>Dining and Nearby Amenities</h2>
                    <div className="Home-about">
                        If you’re not in the mood to cook, you’re in luck! Three excellent restaurants and pubs are
                        within half
                        a mile: Rosie's Mexican Food, CG's Bar and Grill, and The Heyday Inn. For additional shopping,
                        dining,
                        and entertainment, the nearby cities of Yerington (23 miles) and Gardnerville (26 miles) offer a
                        variety
                        of options, including gas stations, local services, and casinos.
                    </div>
                </div>
                <div>
                    <h2>Explore Topaz Lake</h2>
                    <div className="Home-about">
                        We’re located just 11 miles from Topaz Lake, where Nevada meets California. Known for its
                        exceptional
                        trout fishing, Topaz Lake is a favorite destination for fishing enthusiasts.
                    </div>
                </div>
            </div>
            <h2>Stay with Us</h2>
            <div className="Home-about">
                While long-term residents enjoy a 55-and-over community, we welcome guests of all ages to stay for up to
                three months.
            </div>
            <h2 className="Tag-line">Come See What Everyone's Talking About!</h2>
            {/*<h2>Unplug and Recharge</h2>*/}
            {/*<div className="Home-about">*/}
            {/*    Our resort is designed for those seeking peace, tranquility, and a place to recharge. When you visit*/}
            {/*    Wellington Station, plan to completely unplug. Take in the natural beauty, relax, and enjoy the unique*/}
            {/*    slice of paradise we call home. For your convenience, we offer clean and well-maintained showers,*/}
            {/*    restrooms, and an on-site guest laundry for those who need it.*/}
            {/*</div>*/}
            {/*<h2>A Friendly Community and Local Activities</h2>*/}
            {/*<div className="Home-about">*/}
            {/*    At Wellington Station, you’ll feel right at home with our friendly residents, some of the kindest people*/}
            {/*    in Smith Valley. Local events such as car shows, bake sales, carnivals, and rummage sales take place*/}
            {/*    regularly at the Community Hall, just a short walk—only 0.3 miles—from our park.*/}
            {/*</div>*/}
            {/*<h2>Dining and Nearby Amenities</h2>*/}
            {/*<div className="Home-about">*/}
            {/*    If you’re not in the mood to cook, you’re in luck! Three excellent restaurants and pubs are within half*/}
            {/*    a mile: Rosie's Mexican Food, CG's Bar and Grill, and The Heyday Inn. For additional shopping, dining,*/}
            {/*    and entertainment, the nearby cities of Yerington (23 miles) and Gardnerville (26 miles) offer a variety*/}
            {/*    of options, including gas stations, local services, and casinos.*/}
            {/*</div>*/}
            {/*<h2>Explore Topaz Lake</h2>*/}
            {/*<div className="Home-about">*/}
            {/*    We’re located just 11 miles from Topaz Lake, where Nevada meets California. Known for its exceptional*/}
            {/*    trout fishing, Topaz Lake is a favorite destination for fishing enthusiasts.*/}
            {/*</div>*/}
            {/*<h2>Stay with Us</h2>*/}
            {/*<div className="Home-about">*/}
            {/*    While long-term residents enjoy a 55-and-over community, we welcome guests of all ages to stay for up to*/}
            {/*    three months.*/}
            {/*</div>*/}
            {/*<h2>Come See What Everyone's Talking About!</h2>*/}
        </div>
    </div>;
}

export default Home;
