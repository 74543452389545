// import Typography from "@mui/material/Typography";
//
// const PetsInfoCard = () => {
//     return <>
//
//     </>
// }

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import "./ParkRules.css";
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {BulletPoint} from "../utils";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

const PetsInfoCard = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: "#432628" }} aria-label="recipe">
                        2
                    </Avatar>
                }
                title={
                    <Typography variant="h5">
                        Pets Info
                    </Typography>
                }
                action={
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    </ExpandMore>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography mb="2em" variant="h6">
                        Living near each other in a mobile home resort requires some unusual
                        but necessary controls on pets/animals. The following rules on pets
                        have NO EXCEPTIONS.
                    </Typography>
                    <BulletPoint description="No more than 2 dogs at each individual site and you must have
                        management approval PRIOR to bringing an animal home to the
                        park and pay a $150.00 pet deposit per animal if renting here after
                        12/1/24 and going forward. Pet deposits are not required by
                        privately owned homes/RVs etc. that are already in the park." />
                    <BulletPoint description="No more than 2 cats at each individual site and you must have
                        management approval PRIOR to bringing an animal home to the
                        park and pay a $150.00 pet deposit per animal if renting here after
                        12/1/24 and going forward. Pet deposits are not required by
                        privately owned homes/RVs etc. that are already in the park." />
                    <BulletPoint description="No exotic animals such as snakes, eagles, lizards, dragons,
                        hawks, lions, tigers etc. Birds are allowed with pre-approval of
                        management and no more than 2 per site and pay a $150.00 pet
                        deposit per bird if renting here after 12/1/24 and going forward.
                        Pet deposits are not required by privately owned homes/RVs etc
                        that are already in the park." />
                    <BulletPoint description="All dogs MUST be kept on a leash while walking them in the park.
                        Any dog that bites a human in our park will be immediately
                        removed by Lyon County animal control and will not be allowed to
                        return. NO EXCEPTIONS. This is for your safety and your dog’s
                        safety." />
                    <BulletPoint description="Dogs are allowed to be tethered outside unattended for no more
                        than 4 hours per day and ONLY if the weather is above 32 degrees
                        and adequate shelter and water provided. If the weather is above
                        95 degrees, no dogs are allowed to be tethered outside
                        unattended. NO EXCEPTIONS." />
                    <BulletPoint description="Cats are allowed to roam freely outside but please be aware that
                        we have bears, coyotes and other predatory wild animals in the
                        park, and we are NOT RESPONSIBLE for the injury, death or
                        disappearance of any pet that is let outside unattended. We ask
                        that ALL animals be brought in after dark, if possible." />
                    <BulletPoint description="All animals must be kept in sanitary living conditions with A/C in
                        the summer and heat in the winter if they are indoors. This
                        pertains to all residents and campers/guests. Management
                        reserves the right to enter tenants’ homes/property without
                        approval to check on unattended pets, who may be in danger, for
                        safety reasons." />
                    <BulletPoint description="Owners are REQUIRED to pick up and dispose of all pet
                        excrement IMMEDIATELY. No excrement can be left on any site
                        and if smell/ stench is noted or a complaint is filed, the tenant will
                        be fined $25.00 per day for each day after notification to clean up
                        and continue to be fined $25.00 per day there after. NO
                        EXCEPTIONS. If you are walking your animal on park grounds it is
                        YOUR responsibility to pick up excrement. If you are caught NOT
                        picking up your animal excrement, you will be fined $25.00 for
                        each occurrence." />
                    <BulletPoint description="Cats in our Walker Wellington LTD. rental units MUST be provided
                        with a cat litter box if they are indoor cats, and boxes MUST be
                        cleaned and sanitized for their use. Cats are NOT ALLOWED to
                        use pee pads or newspapers in place of a traditional litter box." />
                    <BulletPoint description="Animals MAY NOT be left alone unattended inside individuals’
                        sites for more than 8 hours per day. Please hire a pet sitter or have
                        someone check on your pets. No EXCEPTIONS." />
                    <BulletPoint description="Excessive dog barking WILL NOT be tolerated since we live in such
                        close quarters. Excessive barking both inside a tenant’s residence
                        or outside a tenant’s residence, whether you are at home or away,
                        is grounds for park removal. We will also notify Lyon County
                        Animal Control of the nuisance. Management will notify you
                        before we contact animal control. If we receive more than 3
                        barking complaints in one month, this is grounds for possible
                        eviction or termination of a lease agreement by us. We want
                        everyone to be happy, and we do not want to remove anyone if the
                        issue can be easily solved." />
                    <BulletPoint description="New tenants are requested but not required to show proof of
                        vaccinations, including rabies, bortadella and FVP vaccinations,
                        proof of spay/neutering, and proof of ownership for all new pets
                        entering the property in a leased property if renting here after
                        12/01/2024." />
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default PetsInfoCard;
