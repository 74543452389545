import "./footer.css";

const Footer = () => {
    return <div className="Footer-background">
        <div className="Footer">
            <div className="Footer-title">
                Wellington Station Resort
            </div>
            <div className="Footer-content">
                <div className="Join-email-container">
                    <div className="Join-email-txt">
                        Join our email list and get notified when spaces become available.
                    </div>
                    <iframe className="Iframe"
                            src="https://www.wellingtonstation.com/forms/email-submission.html"
                            title="Wellington Station - Join email list"
                    ></iframe>
                </div>
                <div className="Address-container">
                    <div className="Address">
                        <div style={{fontWeight: 600}}>Physical Address</div>
                        <div>2855 State Route 208</div>
                        <div>Wellington, NV 89444</div>
                    </div>
                    <div className="Flex-column">
                        <div style={{fontWeight: 600}}>Mailing Address</div>
                        <div>P.O. Box 325</div>
                        <div>Wellington, NV 89444</div>
                    </div>
                </div>
                <div className="Address-container">
                    <div className="Address">
                        <div style={{fontWeight: 600}}>Email</div>
                        <div>walkerwellingtonllc@gmail.com</div>
                    </div>
                    <div className="Address">
                        <div style={{fontWeight: 600}}>Office/Rentals</div>
                        <button className="Phone-button">
                            <a className="Phone-link" href="tel:17754951485">(775) 495-1485</a>
                        </button>
                    </div>
                    <div className="Flex-column">
                        <div style={{fontWeight: 600}}>Park Manager Cell (Emergencies)</div>
                        <button className="Phone-button">
                            <a className="Phone-link" href="tel:17754951493">(775) 495-1493</a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default Footer;
