// import Typography from "@mui/material/Typography";
//
// const PetsInfoCard = () => {
//     return <>
//
//     </>
// }

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import "./ParkRules.css";
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {BulletPoint} from "../utils";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

const MotorVehicleInfoCard = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: "#432628" }} aria-label="recipe">
                        3
                    </Avatar>
                }
                title={
                    <Typography variant="h5">
                        Motor Vehicle(s) Storage and Motor Vehicle Info
                    </Typography>
                }
                action={
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    </ExpandMore>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <BulletPoint description="The speed limit in the entire park is 10 MPH. If you or a guest is caught
                        speeding, blaring loud music of any kind, or driving under the
                        influence/erratic, we have the right to cancel your park lease and ask
                        you to move immediately. Driving in our park should be relaxed and
                        mitigate any possibility of an accident. NO EXCEPTIONS" />
                    <BulletPoint description="Riding motorcycles, sand rails, sand cars, dune buggies, golf carts, off
                        road vehicles, ATV, 4-Wheelers, are NOT ALLOWED in the park except
                        for going from your site to the main highway. No one under the age of 16,
                        or anyone without a valid driver’s license, may operate any of the above
                        vehicles on park property." />
                    <BulletPoint description="No more than 2 vehicles are allowed to be parked in front of your site at
                        any given time. If you have guests, they may park along the main
                        roadway near sites 6 – 16 closest to the tree line, near the fencing
                        behind sites 35 -36, near the solar panels or near the maintenance
                        shop. Please make sure they know the speed limit in the park." />
                    <BulletPoint description="There is no car repair/maintenance at individual sites except for
                        automobile washing/detailing. All car repairs such as oil changes, tire
                        changes etc. MUST be done near the park maintenance shop, out of
                        view of tenants or perspective tenants and NOT on individual sites. If
                        your car is not operable it is your responsibility to contact a tow
                        company to move it to be worked on and inoperable cars are not
                        allowed to be kept at any individual site. NO EXCEPTIONS" />
                    <BulletPoint description="If you are paying to use our RV and Camper etc. storage area located
                        near the maintenance shop, you are required to keep your RV or Camper
                        etc. vehicle in good working conditions with no flat tires or noticeable
                        damage. All stored items MUST have tongue locks/hitch locks for YOUR
                        safety and at least one side of wheels MUST be chalked. We are NOT
                        responsible for theft, damage or vandalism while you pay to use the
                        storage area, and you assume all risk." />
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default MotorVehicleInfoCard;
