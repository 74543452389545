import IndividualSiteInfoCard from "../../components/parkRulesCards/IndividualSiteInfoCard";
import Typography from "@mui/material/Typography";
import PetsInfoCard from "../../components/parkRulesCards/PetsInfoCard";
import MotorVehicleInfoCard from "../../components/parkRulesCards/MotorVehicleInfoCard";
import UtilitiesInfoCard from "../../components/parkRulesCards/UtilitiesInfoCard";


const ParkRules = () => {

    return <div
        style={{
            margin: "6em auto 3em",
            display: "flex",
            justifyContent: "center",
            maxWidth: "1500px",
        }}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2em",
            margin: "0 1em",
            width: "100%",
        }}>
            <Typography variant="h4" style={{ textAlign: "center" }}>
                Walker Wellington LTD Park Rules 2024/2025
            </Typography>
            <Typography variant="h5" style={{ textAlign: "center"}}>
                New rules start December 1st, 2024, and are in force until any updates are made by Walker Wellington RV Resort
            </Typography>
            <IndividualSiteInfoCard />
            <PetsInfoCard />
            <MotorVehicleInfoCard />
            <UtilitiesInfoCard />
            <Typography variant="h5" mt={6} style={{ textAlign: "center"}}>Thank you all for following the rules, letting
                us know if rules are being bent, and for
                being the best tenants around!</Typography>
        </div>
    </div>
};

export default ParkRules;
