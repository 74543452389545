// import Typography from "@mui/material/Typography";
//
// const PetsInfoCard = () => {
//     return <>
//
//     </>
// }

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import "./ParkRules.css";
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {BulletPoint} from "../utils";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

const UtilitiesInfoCard = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: "#432628" }} aria-label="recipe">
                        4
                    </Avatar>
                }
                title={
                    <Typography variant="h5">
                        Utilities Info
                    </Typography>
                }
                action={
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    </ExpandMore>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <BulletPoint description="Water, septic tanks, and trash dumpsters are provided to tenants at no
                        additional charge. At a future date, we may need to change this to
                        provide the funds for ongoing maintenance costs/repairs. We will notify
                        everyone in writing at least 90 days before any proposed changes in this
                        policy." />
                    <BulletPoint description="We ask that all residents and campers DO NOT flush any feminine
                        products, wet wipes (flushable or not), large wads of toilet paper, food,
                        trash, or any other item that can damage our septic systems while
                        inside your residence or in our bathroom facilities. Please help us by
                        being gentle on our septic system and if you have any septic back-ups,
                        water issues or septic smells that you immediately notify us. Putting one
                        packet of $.33 cent baking yeast in your toilet monthly can also help our
                        systems. (Just ask us about how that works!) Do not use any harsh
                        chemicals or unclogging liquids/toilet bowl cleaners that are NOT septic
                        safe. This will save us all from repairs where we must shut off the water/
                        not use the toilets which inconveniences us all." />
                    <BulletPoint description="Mobile / Tiny Home Sites 1-2 and 5-16 are responsible for obtaining
                        services from NV Energy, and Jim Menesini propane and paying the
                        providers directly. It is the sole responsibility of tenants to always
                        maintain electricity through NV Energy. If you cannot pay your electric
                        bill, please contact NV Energy and make payment arrangements. No
                        privately owned/rented solar panels are allowed to power or be on these
                        sites and no one is allowed to be “off grid” while residing in our park. NO
                        EXCEPTIONS." />
                    <BulletPoint description="RV / Tiny Home Sites 17 – 36 are responsible for obtaining services from
                        Jim Menesini propane (if needed). The park will read the electricity
                        meters at these sites on the 27th of each month and provide the
                        electricity costs when we deliver your monthly statements of rent for
                        long-term residents. If you are a camper staying less than 30 days in
                        these sites, you DO NOT have to pay the site electric cost. Any camper
                        staying longer than 30 days will be responsible for electricity costs.
                        Campers who wish to stay past 30 days MUST have park management
                        approval, move sites every 30 days, and will be responsible for site
                        electricity cost. No camper can stay past 90 days at our park unless
                        they rent the equipment/site/home from us. Privately owned equipment
                        must vacate site(s) at 90 days. No privately owned/rented solar panels
                        are allowed to power or be on these sites and no one is allowed to be
                        “off grid” while residing in our park. NO EXCEPTIONS." />
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default UtilitiesInfoCard;
