import "./header-navigation.css";
import { Link } from "react-router-dom";
import {useState} from "react";

const HeaderNavigation = () => {
    const [displayMobileMenu, setDisplayMobileMenu] = useState<boolean>(false);
    return (
        <div>
            <div className="Header-nav-container">
                <div>
                    <div className="Nav-link-logo">
                        <Link to="/home">
                            <img src={require("../../assets/images/WSR_Logo_transparent.png")}
                                 alt="WSR Logo"
                                 className="Logo"
                            />
                        </Link>
                    </div>
                </div>
                <div style={{
                    paddingBottom: "3em",
                    display: "flex",
                    gap: "4em"
                }}>
                    <Link to="/home" className="Nav-link">Home</Link>
                    <Link to="/gallery" className="Nav-link">Gallery</Link>
                    <Link to="/directions" className="Nav-link">Directions</Link>

                    <Link to="/park-rules"
                          className="Nav-link"
                          style={{ textWrap: "nowrap" }}>Park Rules</Link>
                    <Link to="/rates" className="Nav-link">Rates</Link>
                    <Link to="/contact" className="Nav-link">Contact</Link>
                </div>
            </div>
            <div className="Mobile-menu">
                <div className="Mobile-menu-bar">
                    <div>
                        <a href="tel:+17754652409">
                            <i className="fa fa-phone Mobile-menu-icons" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div className="Mobile-menu-logo-container">
                        <img src={require("../../assets/images/WSR_Logo_transparent.png")}
                             alt="WSR Logo - mobile menu"
                             className="Mobile-menu-logo"
                        />
                    </div>
                    <div onClick={() => setDisplayMobileMenu(!displayMobileMenu)}>
                        <i className="fa fa-bars Mobile-menu-icons"></i>
                    </div>
                </div>
                {displayMobileMenu && (
                    <div className="Mobile-menu-links"
                         onClick={() =>setDisplayMobileMenu(!displayMobileMenu)}>
                        <Link to="/home" className="Nav-link">Home</Link>
                        <Link to="/gallery" className="Nav-link">Gallery</Link>
                        <Link to="/directions" className="Nav-link">Directions</Link>
                        <Link to="/park-rules" className="Nav-link">Park Rules</Link>
                        <Link to="/rates" className="Nav-link">Rates</Link>
                        <Link to="/contact" className="Nav-link">Contact</Link>
                    </div>
                )}
            </div>
        </div>);
}

export default HeaderNavigation;
