import Typography from "@mui/material/Typography";
import * as React from "react";

interface BulletPointProps {
    description: string | React.ReactNode;
}

export const BulletPoint = ({ description }: BulletPointProps) => {
    return <div className="List-bullet-point">
        <i className="fa fa-circle" style={{ fontSize: "10px" }} aria-hidden="true"></i>
        <Typography variant="h6" sx={{marginBottom: 2}}>
            {description}
        </Typography>
    </div>
}
